<template>
  <!-- style="display: block; float: left" -->
  <img
    :style="styling"
    src="@/assets/images/logo/mologiz-gray.png"
    alt="Logo Mologiz"
  />
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  width: {
    type: [String, Number],
    default: 108,
  },
  height: {
    type: [String, Number],
    default: 18,
  },
});
const styling = computed(() => {
  return {
    width: `${props.width}px`,
    height: `${props.height}px`,
  };
});
</script>
