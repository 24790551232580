import { useToast } from "vue-toastification";
import * as fs from "file-saver";
import { downloadExcel as driverDownloadExcel } from "@/utils/export-excel";

const toast = useToast();

export function redirectNotification(status, reference) {
  switch (status) {
    case "NOTIF.CATEGORY.00":
      return { path: "/vendor-management/list/detail/?id=" + reference };
    case "NOTIF.CATEGORY.01":
      return {
        name: "order-detail",
        params: { id: reference },
      };
    case "NOTIF.CATEGORY.02":
      return {
        name: "order-detail",
        params: { id: reference },
      };
    case "NOTIF.CATEGORY.03":
      return {
        name: "finance.detail",
        params: { id: reference },
      };
    case "NOTIF.CATEGORY.04":
      return {
        name: "finance.detail",
        params: { id: reference },
      };
    case "NOTIF.CATEGORY.05":
      return {
        name: "finance.detail",
        params: { id: reference },
      };
    case "NOTIF.CATEGORY.06":
      return {
        name: "finance.detail",
        params: { id: reference },
      };
    case "NOTIF.CATEGORY.07":
      return {
        name: "finance.detail",
        params: { id: reference },
      };
    case "NOTIF.CATEGORY.08":
      return {
        name: "order-detail",
        params: { id: reference },
      };
    case "NOTIF.CATEGORY.09":
      return {
        name: "order-detail",
        params: { id: reference },
      };
    case "NOTIF.CATEGORY.10":
      return {
        name: "order-detail",
        params: { id: reference },
      };
    case "NOTIF.CATEGORY.11":
      return {
        name: "order-detail",
        params: { id: reference },
      };
    case "NOTIF.CATEGORY.12":
      return {
        name: "order-detail",
        params: { id: reference },
      };
    case "NOTIF.CATEGORY.13":
      return {
        name: "order-detail",
        params: { id: reference },
      };
    case "NOTIF.CATEGORY.14":
      return {
        name: "order-detail",
        params: { id: reference },
      };
    case "NOTIF.CATEGORY.15":
      return {
        name: "order-detail",
        params: { id: reference },
      };
    default:
      return { path: "/" };
  }
}

export function convertToCSV(objArray) {
  let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";
  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let index in array[i]) {
      if (line != "") line += ";";
      line += array[i][index];
    }
    str += line + "\r\n";
  }
  return str;
}

export function getColorStatusCode(code) {
  if (code === 200 || code === 201 || code === 202) {
    return "success";
  } else if (
    code === 400 ||
    code === 401 ||
    code === 402 ||
    code === 403 ||
    code === 404
  ) {
    return "primary";
  } else if (code === 500 || code === 501 || code === 502) {
    return "error";
  } else {
    return "gray";
  }
}

export function copiedText(val, msg = "") {
  navigator.clipboard.writeText(val);
  if (val) {
    if (msg) {
      toast.success(msg);
    } else {
      toast.success("" + val + " berhasil disalin");
    }
  } else {
    toast.warning("Gagal salin teks");
  }
}

export function downloadFormatExcel(nameFile, headers, items) {
  if (items.length > 0) {
    const fileName = nameFile;
    let data = items;
    const { workbook } = driverDownloadExcel(headers, data, fileName);
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, `${fileName}.xlsx`);
    });
  } else {
    toast.warning("Tidak ada data");
  }
}

export function getNameFile(val) {
  if (val) return val.substring(val.lastIndexOf("/") + 1).toLowerCase();
  return "";
}

export function getContractParameter(
  typeContract = "rate",
  whoDis = "shipper",
  value,
  defaultNull = "-"
) {
  // typeContract = rate / schema
  // whoDis = transporter/ shipper

  if (value) {
    let listVal = value.split("|");
    if (typeContract == "rate" && whoDis == "shipper" && listVal.length > 0)
      return listVal[0] ? listVal[0].trim() : defaultNull;
    if (typeContract == "rate" && whoDis == "transporter" && listVal.length > 1)
      return listVal[1] ? listVal[1].trim() : defaultNull;
    if (typeContract == "schema" && whoDis == "shipper" && listVal.length > 2)
      return listVal[2] ? listVal[2].trim() : defaultNull;
    if (
      typeContract == "schema" &&
      whoDis == "transporter" &&
      listVal.length > 3
    )
      return listVal[3] ? listVal[3].trim() : defaultNull;
    return defaultNull;
  }
  return defaultNull;
}

export function findDuplicates(arr) {
  const duplicates = new Set();

  return arr.filter((item) => {
    if (duplicates.has(item)) {
      return true;
    }
    duplicates.add(item);
    return false;
  });
}

export function mergeGoods(routeType, origins, destinations) {
  var goods = [];
  if (routeType == "many_to_one") {
    goods = origins
      .map((g, i) => {
        let takeGoods = g.items.map((p) => {
          p.origin = i;
          p.destination = 0;
          return p;
        });
        return takeGoods;
      })
      .reduce((initial, current) => [...initial, ...current], []);
  } else {
    goods = destinations
      .map((g, i) => {
        let takeGoods = g.items.map((p) => {
          p.origin = 0;
          p.destination = i;
          return p;
        });
        return takeGoods;
      })
      .reduce((initial, current) => [...initial, ...current], []);
  }
  return goods;
}

export function trimsText(val) {
  try {
    let text = val;
    return text.trim();
  } catch (err) {
    console.log(err);
    return val;
  }
}
